import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { throwError } from "rxjs";
import { UserService } from "../user/user.services";
import { Router } from "@angular/router";
import { catchError, map } from "rxjs/operators";
import { generate } from "shortid";

@Injectable()
export class RemoteService {
  // server_home_url =
  //  "http://ec2-52-66-211-73.ap-south-1.compute.amazonaws.com/api";

  //server_home_url = "http://localhost:8080/api";

  server_home_url = "https://join.naamtamilar.org/api";

  private c_headers: HttpHeaders = new HttpHeaders();

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private router: Router
  ) {}

  handleError(error: HttpErrorResponse) {
    if (error.status == 0) {
      return throwError("இணைய இணைப்பு சரியாக இல்லை");
    } else if (error.status === 401) {
      localStorage.removeItem("ntkuser");
      localStorage.removeItem("ntkhdoff");
      this.router.navigate(["login"]);
    } else if (error.error instanceof ErrorEvent) {
      if (error.message) {
        return throwError(error.message);
      } else {
        return throwError("Unknown Error");
      }
    } else {
      if (error.message) {
        return throwError(error.message);
      } else {
        return throwError("Unknown Error");
      }
    }
  }

  doLogin(name, password) {
    this.c_headers = new HttpHeaders().set(
      "Authorization",
      "Base " + btoa(name + ":" + password)
    );
    return this.http
      .get(this.server_home_url + "/login", { headers: this.c_headers })
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  // Forgot Password

  forgotPassword(no) {
    return this.http
      .get(this.server_home_url + "/forgotpass/" + String(no))
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  //generatePassword

  generateNewPwd(mid) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .get(
        this.server_home_url + "/admin/member/password/generate/" + String(mid),
        { headers: this.c_headers, responseType: "json" }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  //MemberBaseInfo

  getMemberBaseInfo(mid) {
    return this.http
      .get(this.server_home_url + "/base/name/" + String(mid))
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  //Creator Info

  getCreatorInfo(mid) {
    return this.http
      .get(this.server_home_url + "/creator/info/" + String(mid))
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getShield(mid) {
    return this.http
      .get(this.server_home_url + "/shield/type/" + String(mid))
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  /**
   * Join Events Function Modules
   */

  getTotalJoined() {
    return this.http.get(this.server_home_url + "/join/event/total").pipe(
      map((data) => data),
      catchError(this.handleError)
    );
  }

  getTotalJoinedByGender(gender) {
    return this.http
      .get(this.server_home_url + "/join/event/gender/" + String(gender))
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }
  //voterID udpate

  updateVoterID(formData) {
    this.c_headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http
      .post(
        this.server_home_url + "/voterid/update",
        JSON.stringify(formData),
        { headers: this.c_headers, responseType: "json" }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  //donate appeal

  donateEntry(formData) {
    this.c_headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http
      .post(this.server_home_url + "/donate/appeal", JSON.stringify(formData), {
        headers: this.c_headers,
        responseType: "json",
      })
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  // change password

  changePassword(formData) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .post(
        this.server_home_url + "/member/change/password",
        JSON.stringify(formData),
        { headers: this.c_headers }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  /**
   * Upload member photo to S3 server
   * @param file
   */
  uploadMemberPhoto(file, mid) {
    let formData: FormData = new FormData();
    let extension = file.name.split(".").pop();
    formData.append("single", file, mid + "." + extension);
    return this.http
      .post(this.server_home_url + "/upload/photo", formData, {
        responseType: "text",
      })
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  isSessionvalid() {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });

    return this.http
      .get(this.server_home_url + "/token/expiry", {
        headers: this.c_headers,
        responseType: "text",
      })
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  validateUserData(formdata) {
    if (this.userService && this.userService.isValidSession()) {
      this.c_headers = new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.userService.getToken()}`,
      });
    } else {
      this.c_headers = new HttpHeaders({
        "Content-Type": "application/json",
      });
    }
    return this.http
      .post(this.server_home_url + "/validate/form", JSON.stringify(formdata), {
        headers: this.c_headers,
        responseType: "json",
      })
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  // Auth API Services

  isLocalAdmin() {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .get(this.server_home_url + "/member/privileage", {
        headers: this.c_headers,
        responseType: "json",
      })
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getAccessMode() {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .get(this.server_home_url + "/member/accessmode", {
        headers: this.c_headers,
      })
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  otpSend(formData) {
    this.c_headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http
      .post(this.server_home_url + "/otp/send", JSON.stringify(formData), {
        headers: this.c_headers,
        responseType: "json",
      })
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  otpVerify(formdata) {
    this.c_headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http
      .post(this.server_home_url + "/otp/verify", JSON.stringify(formdata), {
        headers: this.c_headers,
        responseType: "json",
      })
      .toPromise();
  }

  // Constituency Summmary

  getConstituencySummary(cid) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .get(this.server_home_url + "/member/summary/" + String(cid), {
        headers: this.c_headers,
      })
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getNonAdminLocations(cid) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .get(this.server_home_url + "/member/nonadmin/location/" + String(cid), {
        headers: this.c_headers,
      })
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getMemberTrend(cid) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .get(this.server_home_url + "/member/monthly/trend/" + String(cid), {
        headers: this.c_headers,
      })
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getTopRankingBoothAgent(cid) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .get(
        this.server_home_url + "/member/booth/agent/ranking/" + String(cid),
        {
          headers: this.c_headers,
        }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getRepsTrend(cid) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .get(this.server_home_url + "/member/active/reps/data/" + String(cid), {
        headers: this.c_headers,
      })
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getEventTrends(cid) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .get(this.server_home_url + "/member/event/monthly/data/" + String(cid), {
        headers: this.c_headers,
      })
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }
  getBoothAgentTrend(cid) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .get(this.server_home_url + "/member/booth/agent/trend/" + String(cid), {
        headers: this.c_headers,
      })
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getMyConstituencyContacts(cid) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .get(
        this.server_home_url + "/member/myconstituency/replist/" + String(cid),
        { headers: this.c_headers }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getAddress(id) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .get(this.server_home_url + "/member/office/address/" + String(id), {
        headers: this.c_headers,
      })
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  updateAddress(formData, cid) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .post(
        this.server_home_url + "/admin/address/update/" + String(cid),
        JSON.stringify(formData),
        { headers: this.c_headers, responseType: "text" }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getReplist(id, dpid) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .get(
        this.server_home_url +
          "/member/replist/" +
          String(id) +
          "/" +
          String(dpid) +
          "/0",
        { headers: this.c_headers }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getRawReplist(id, dpid) {
    this.c_headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http
      .get(
        this.server_home_url + "/replist/" + String(id) + "/" + String(dpid),
        { headers: this.c_headers }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  /**
   *  Location Services
   **/

  getLivingList() {
    return this.http
      .get(this.server_home_url + "/location/livingplace/list")
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getAreaType() {
    return this.http
      .get(this.server_home_url + "/location/area/type/list")
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getListByLevel(id) {
    return this.http
      .get(this.server_home_url + "/location/arealist/hlevel/" + String(id))
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getListByParent(id) {
    return this.http
      .get(this.server_home_url + "/location/list/parent/" + String(id))
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getAreaList(id, level) {
    return this.http
      .get(
        this.server_home_url +
          "/location/area/list/" +
          String(id) +
          "/" +
          String(level)
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getCountryList() {
    return this.http.get(this.server_home_url + "/location/country/list").pipe(
      map((data) => data),
      catchError(this.handleError)
    );
  }

  getTNStateList(id) {
    return this.http
      .get(this.server_home_url + "/location/state/list/" + String(id))
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getDistrictList(stateid) {
    return this.http
      .get(this.server_home_url + "/location/district/list/" + String(stateid))
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getListByLevelParent(level, id) {
    return this.http
      .get(
        this.server_home_url +
          "/location/list/parentlevel/" +
          String(level) +
          "/" +
          String(id)
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError.bind(this))
      );
  }

  findDistrictID(cid) {
    return this.http
      .get(this.server_home_url + "/location/district/find/" + String(cid))
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getUnionPanchayat(did) {
    return this.http
      .get(
        this.server_home_url + "/location/district/union/list/" + String(did)
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getVillagePanchayat(uid) {
    return this.http
      .get(
        this.server_home_url +
          "/location/district/panchayat/list/" +
          String(uid)
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getConstituencyList(typeid) {
    return this.http
      .get(
        this.server_home_url + "/location/constituency/list/" + String(typeid)
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getConstituencyListByDistrict(id) {
    return this.http
      .get(
        this.server_home_url +
          "/location/district/constituency/list/" +
          String(id)
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getAllSubDivisions() {
    return this.http.get(this.server_home_url + "/location/division/all").pipe(
      map((data) => data),
      catchError(this.handleError)
    );
  }

  /** Member Services */

  getITAdminList() {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .get(this.server_home_url + "/member/itadmin/list", {
        headers: this.c_headers,
        responseType: "json",
      })
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getFilterOptions() {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .get(
        this.server_home_url +
          "/member/filterlist/options/" +
          String(this.userService.getAdminConstituencyID()),
        { headers: this.c_headers, responseType: "json" }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getMemberCert(mid) {
    this.c_headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http
      .get(this.server_home_url + "/certificate/member/" + String(mid), {
        headers: this.c_headers,
        responseType: "arraybuffer",
      })
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  createNewMember(formData) {
    if (this.userService && this.userService.isValidSession()) {
      this.c_headers = new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.userService.getToken()}`,
      });
    } else {
      this.c_headers = new HttpHeaders({
        "Content-Type": "application/json",
      });
    }
    return this.http
      .post(
        this.server_home_url + "/create/memberid",
        JSON.stringify(formData),
        { headers: this.c_headers, responseType: "json" }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  updateMemberInfo(formData, cid) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });

    return this.http
      .post(
        this.server_home_url + "/member/update/memberdetails/" + String(cid),
        JSON.stringify(formData),
        { headers: this.c_headers, responseType: "json" }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  updateMemberAddInfo(formData, cid) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });

    return this.http
      .post(
        this.server_home_url +
          "/member/update/userinfo/additional/" +
          String(cid),
        JSON.stringify(formData),
        { headers: this.c_headers }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getMemberAddInfo(mid, cid) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });

    return this.http
      .get(
        this.server_home_url +
          "/member/update/userinfo/additional/" +
          String(mid) +
          "/" +
          String(cid),
        { headers: this.c_headers }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  approveMember(cid, mid) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .get(
        this.server_home_url +
          "/member/approve/" +
          String(cid) +
          "/" +
          String(mid),
        { headers: this.c_headers }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  unapproveMember(cid, mid) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .get(
        this.server_home_url +
          "/member/unapprove/" +
          String(cid) +
          "/" +
          String(mid),
        { headers: this.c_headers }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  removeRequest(formData) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .post(
        this.server_home_url +
          "/member/remove/member/" +
          String(this.userService.getConstituencyID()),
        JSON.stringify(formData),
        { headers: this.c_headers, responseType: "text" }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  removeMemberPDF(fdata) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .post(
        this.server_home_url + "/admin/member/remove/pdf",
        JSON.stringify(fdata),
        { headers: this.c_headers, responseType: "arraybuffer" }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  rejectRequest(id, formData) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .post(
        this.server_home_url + "/admin/reject/deleterequest/" + String(id),
        JSON.stringify(formData),
        { headers: this.c_headers, responseType: "text" }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  updateDeleteRecord(id) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .get(this.server_home_url + "/admin/update/deletemember/" + String(id), {
        headers: this.c_headers,
        responseType: "text",
      })
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getDeletedRecords(typeid) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .get(this.server_home_url + "/admin/blocklist/" + String(typeid), {
        headers: this.c_headers,
      })
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  deletePhoto(cid, mid) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .get(
        this.server_home_url +
          "/member/delete/photo/" +
          String(cid) +
          "/" +
          String(mid),
        { headers: this.c_headers, responseType: "text" }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  portalNewMember(formData) {
    this.c_headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http
      .post(this.server_home_url + "/new/member", JSON.stringify(formData), {
        headers: this.c_headers,
      })
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getMemberInfoById(mid, cid) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .get(
        this.server_home_url +
          "/member/fetch/" +
          String(mid) +
          "/" +
          String(cid),
        { headers: this.c_headers }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getMemberInfoRO(mid, token) {
    this.c_headers = new HttpHeaders().set("x-access-token", token);
    return this.http
      .get(this.server_home_url + "/member/fetch/" + String(mid), {
        headers: this.c_headers,
      })
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getMemberPosition(cid, mid) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .get(
        this.server_home_url +
          "/member/position/info/" +
          String(cid) +
          "/" +
          String(mid),
        { headers: this.c_headers }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getLocalAdminList(ltype) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .get(this.server_home_url + "/member/info/admin/" + String(ltype), {
        headers: this.c_headers,
      })
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  isValidAdmin(cid) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .get(this.server_home_url + "/member/valid/admin/" + String(cid), {
        headers: this.c_headers,
        responseType: "text",
      })
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getProfile() {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .get(this.server_home_url + "/member/profile", {
        headers: this.c_headers,
      })
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getMyConstituencyList(ltype) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .get(
        this.server_home_url + "/member/myconstituency/list/" + String(ltype),
        { headers: this.c_headers }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getMembersByCreator(pageno) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .get(this.server_home_url + "/member/list/me/" + String(pageno), {
        headers: this.c_headers,
        responseType: "json",
      })
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getMyLivingList() {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .get(this.server_home_url + "/member/myplace/list", {
        headers: this.c_headers,
      })
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getMySubDivisions(hlevel, cid) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .get(
        this.server_home_url +
          "/member/constituency/division/list/" +
          String(hlevel) +
          "/" +
          String(cid),
        { headers: this.c_headers }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getSubDivisions(hlevel, cid) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .get(
        this.server_home_url +
          "/member/division/list/" +
          String(hlevel) +
          "/" +
          String(cid),
        { headers: this.c_headers }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getMyHirerachyList() {
    this.c_headers = new HttpHeaders().set(
      "x-access-token",
      this.userService.getToken()
    );
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .get(this.server_home_url + "/member/myhirerachy/list", {
        headers: this.c_headers,
      })
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getBulkCardJobs() {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .get(this.server_home_url + "/batch/query/cards/list", {
        headers: this.c_headers,
      })
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getRepMonCreated(mid) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .get(
        this.server_home_url + "/member/rep/monitor/created/" + String(mid),
        {
          headers: this.c_headers,
        }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getRepMonApproved(mid) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .get(
        this.server_home_url + "/member/rep/monitor/approved/" + String(mid),
        {
          headers: this.c_headers,
        }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  downloadCardZip(jobid) {
    this.c_headers = new HttpHeaders({
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .get(this.server_home_url + "/batch/download/archive/" + String(jobid), {
        headers: this.c_headers,
        responseType: "arraybuffer",
      })
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  addBulkCardJob(cid, list, template) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .post(
        this.server_home_url + "/batch/add/card/new/" + String(cid),
        JSON.stringify({ memberslist: list, cardtype: parseInt(template) }),
        { headers: this.c_headers }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  createCard(id, type) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .get(
        this.server_home_url +
          "/batch/get/card/" +
          String(id) +
          "/" +
          String(type),
        { headers: this.c_headers, responseType: "arraybuffer" }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  filterQuery(pageno, queryparams, cid) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });

    return this.http
      .post(
        this.server_home_url +
          "/member/filter/" +
          String(cid) +
          "/" +
          String(pageno),
        queryparams,
        { headers: this.c_headers }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  filterQueryExcel(pageno, queryparams, cid) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });

    return this.http
      .post(
        this.server_home_url +
          "/member/filter/" +
          String(cid) +
          "/" +
          String(pageno),
        JSON.stringify(queryparams),
        { headers: this.c_headers, responseType: "arraybuffer" }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  filterQueryPDF(pageno, queryparams, cid) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });

    return this.http
      .post(
        this.server_home_url +
          "/member/filter/" +
          String(cid) +
          "/" +
          String(pageno),
        JSON.stringify(queryparams),
        { headers: this.c_headers, responseType: "arraybuffer" }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  filterIDCardQuery(cid, ftype) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .get(
        this.server_home_url +
          "/member/card/filter/" +
          String(cid) +
          "/" +
          String(ftype),
        { headers: this.c_headers }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  searchMember(pageno, cid, formData, filtertype) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .post(
        this.server_home_url +
          "/member/search/" +
          String(cid) +
          "/" +
          String(filtertype) +
          "/" +
          String(pageno),
        JSON.stringify(formData),
        { headers: this.c_headers }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getHierarchyList() {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });

    return this.http
      .get(this.server_home_url + "/admin/hierarchy/list", {
        headers: this.c_headers,
      })
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getDepartmentList() {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .get(this.server_home_url + "/member/department/list", {
        headers: this.c_headers,
      })
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getPosition(cid, departid, ward) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });

    return this.http
      .get(
        this.server_home_url +
          "/admin/position/" +
          String(cid) +
          "/" +
          String(departid) +
          "/" +
          String(ward),
        { headers: this.c_headers }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getWaitingPositions() {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .get(this.server_home_url + "/admin/waiting/positions", {
        headers: this.c_headers,
      })
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  approvePosition(id) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .get(this.server_home_url + "/admin/position/approve/" + String(id), {
        headers: this.c_headers,
        responseType: "text",
      })
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  approveAllPosition(rows) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .post(this.server_home_url + "/admin/position/bulk/approve", rows, {
        headers: this.c_headers,
      })
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  isEligibleCandidate(cid, mid, formData) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .post(
        this.server_home_url +
          "/admin/position/eligible/" +
          String(cid) +
          "/" +
          String(mid),
        JSON.stringify(formData),
        { headers: this.c_headers }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }
  createPosition(cid, formdata) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .post(
        this.server_home_url + "/admin/position/create/" + String(cid),
        formdata,
        { headers: this.c_headers, responseType: "text" }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  editPosition(cid, formdata) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .post(
        this.server_home_url + "/admin/position/update/" + String(cid),
        formdata,
        { headers: this.c_headers, responseType: "text" }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  deletePosition(cid, id, reason) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .post(
        this.server_home_url + "/admin/position/delete/" + String(cid),
        JSON.stringify({ id: id, reason: reason }),
        { headers: this.c_headers, responseType: "text" }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getDeletedReason(mid) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .get(this.server_home_url + "/admin/user/deleted/reason/" + String(mid), {
        headers: this.c_headers,
      })
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  printReps(pid, department) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .get(
        this.server_home_url +
          "/admin/position/print?placeid=" +
          String(pid) +
          "&department=" +
          department,
        { headers: this.c_headers, responseType: "arraybuffer" }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  downloadRepContacts(pid, department) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .get(
        this.server_home_url +
          "/admin/representatives/details/" +
          String(this.userService.getAdminConstituencyID()) +
          "?placeid=" +
          String(pid) +
          "&department=" +
          department,
        { headers: this.c_headers, responseType: "json" }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  /**
   * Area Add/Edit/Delete functions
   */

  addArea(formData) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .post(
        this.server_home_url + "/admin/area/add",
        JSON.stringify(formData),
        { headers: this.c_headers, responseType: "text" }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  editArea(formData) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .post(
        this.server_home_url + "/admin/area/edit",
        JSON.stringify(formData),
        { headers: this.c_headers, responseType: "text" }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  deleteArea(id) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .get(this.server_home_url + "/admin/area/delete/" + String(id), {
        headers: this.c_headers,
        responseType: "text",
      })
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  searchArea(text_string) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .post(
        this.server_home_url + "/admin/area/search",
        JSON.stringify({ pattern: String(text_string) }),
        { headers: this.c_headers }
      )
      .pipe(map((response) => response[1]));
  }

  editAreaHierarchy(formData) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .post(
        this.server_home_url + "/admin/area/level/edit",
        JSON.stringify(formData),
        { headers: this.c_headers, responseType: "text" }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  linkArea(parent, place) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    const formData = {
      place: place,
      pid: parent,
    };
    return this.http
      .post(
        this.server_home_url +
          "/member/area/link/" +
          String(this.userService.getAdminConstituencyID()),
        JSON.stringify(formData),
        { headers: this.c_headers, responseType: "json" }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  resetlink(formdata) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .post(
        this.server_home_url + "/admin/area/reset/link",
        JSON.stringify(formdata),
        {
          headers: this.c_headers,
        }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError.bind(this))
      );
  }

  setVlink(formdata) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .post(
        this.server_home_url + "/admin/area/set/link",
        JSON.stringify(formdata),
        { headers: this.c_headers }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError.bind(this))
      );
  }

  getListByVlink(id, level) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .get(
        this.server_home_url +
          "/admin/area/list/vlink/level/" +
          String(id) +
          "/" +
          String(level),
        { headers: this.c_headers }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError.bind(this))
      );
  }

  addVlink4Const(formData) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });

    return this.http
      .post(
        this.server_home_url + "/admin/area/subdistrict/vlink/add",
        JSON.stringify(formData),
        { headers: this.c_headers, responseType: "json" }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  addBulkVlink(formData) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });

    return this.http
      .post(
        this.server_home_url + "/admin/area/bulk/vlink/add",
        JSON.stringify(formData),
        { headers: this.c_headers, responseType: "json" }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  removeVlink4Const(formData) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });

    return this.http
      .post(
        this.server_home_url + "/admin/area/subdistrict/vlink/remove",
        JSON.stringify(formData),
        { headers: this.c_headers, responseType: "json" }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  updateZoneChilds(data) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });

    return this.http
      .post(
        this.server_home_url + "/admin/zone/childs/update",
        JSON.stringify(data),
        { headers: this.c_headers, responseType: "json" }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getNoAdminConstituency() {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .get(this.server_home_url + "/admin/get/noadmin/constituency", {
        headers: this.c_headers,
      })
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getAreaEventList(place, departid, pageno) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .get(
        this.server_home_url +
          "/member/area/events/list/" +
          String(place) +
          "/" +
          String(departid) +
          "/" +
          String(pageno),
        { headers: this.c_headers }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  addAreaEvent(formData, place, departid) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .post(
        this.server_home_url +
          "/member/area/events/add/" +
          String(place) +
          "/" +
          String(departid),
        JSON.stringify(formData),
        { headers: this.c_headers, responseType: "json" }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  updateAreaEvent(formData, place, departid) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .post(
        this.server_home_url +
          "/member/area/events/update/" +
          String(place) +
          "/" +
          String(departid),
        JSON.stringify(formData),
        { headers: this.c_headers, responseType: "json" }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  deleteAreaEvent(pfilter, id) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });

    const data = {
      id: id,
      pfilter: pfilter,
    };
    return this.http
      .post(
        this.server_home_url +
          "/member/area/events/delete/" +
          String(this.userService.getAdminConstituencyID()),
        JSON.stringify(data),
        { headers: this.c_headers }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getDefaultEventList() {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });

    return this.http
      .get(this.server_home_url + "/member/events/default/list", {
        headers: this.c_headers,
      })
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  /** Goals API */

  getGoals(cid, year, quarter) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });

    return this.http
      .get(
        this.server_home_url +
          "/admin/target/" +
          String(cid) +
          "/" +
          String(year) +
          "/" +
          String(quarter),
        { headers: this.c_headers, responseType: "json" }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  updateGoals(formData, cid) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .post(
        this.server_home_url + "/admin/target/update/" + String(cid),
        JSON.stringify(formData),
        { headers: this.c_headers, responseType: "json" }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  addGoals(formData, cid) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .post(
        this.server_home_url + "/admin/target/add/" + String(cid),
        JSON.stringify(formData),
        { headers: this.c_headers, responseType: "json" }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  deleteGoals(id, cid) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .post(
        this.server_home_url +
          "/admin/target/delete/" +
          String(cid) +
          "/" +
          String(id),
        { headers: this.c_headers, responseType: "json" }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  /** Polling Stations API  */

  getPollingStationsFile(cid) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .get(
        this.server_home_url + "/member/boothlist/files/:cid/" + String(cid),
        { headers: this.c_headers, responseType: "json" }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getPollingStations(cid) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .get(
        this.server_home_url +
          "/member/pollingbooth/list/" +
          String(this.userService.getAdminConstituencyID()) +
          "/" +
          String(cid),
        { headers: this.c_headers, responseType: "json" }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  addPollingStation(cid, formdata) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .post(
        this.server_home_url + "/member/pollingbooth/add/" + String(cid),
        JSON.stringify(formdata),
        { headers: this.c_headers, responseType: "json" }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  updatePollingStation(cid, formdata) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .post(
        this.server_home_url + "/member/pollingbooth/update/" + String(cid),
        JSON.stringify(formdata),
        { headers: this.c_headers, responseType: "json" }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  deletePollingStation(cid, id) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .post(
        this.server_home_url +
          "/member/pollingbooth/delete/" +
          String(cid) +
          "/" +
          String(id),
        { headers: this.c_headers, responseType: "json" }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  /**Booth Agent API */

  addBoothAgent(cid, mid, bid) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });

    return this.http
      .get(
        this.server_home_url +
          "/member/boothagent/add/" +
          String(cid) +
          "/" +
          String(mid) +
          "/" +
          String(bid),
        { headers: this.c_headers, responseType: "text" }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  deleteBoothAgent(cid, id) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .get(
        this.server_home_url +
          "/member/boothagent/delete/" +
          String(cid) +
          "/" +
          String(id),
        { headers: this.c_headers, responseType: "text" }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getBoothAgents(cid, boothid) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .get(
        this.server_home_url +
          "/member/boothagent/list/" +
          String(cid) +
          "/" +
          String(boothid),
        { headers: this.c_headers, responseType: "json" }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  /**Voter List API */

  createVoter(cid, boothid, formdata) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .post(
        this.server_home_url +
          "/member/voter/add/" +
          String(cid) +
          "/" +
          String(boothid),
        JSON.stringify(formdata),
        { headers: this.c_headers, responseType: "json" }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getVoterList(cid, boothid) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .get(
        this.server_home_url +
          "/member/voter/list/" +
          String(cid) +
          "/" +
          String(boothid),
        { headers: this.c_headers, responseType: "json" }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getMemVoterList(cid, boothid) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .get(
        this.server_home_url +
          "/member/voter/member/list/" +
          String(cid) +
          "/" +
          String(boothid),
        { headers: this.c_headers, responseType: "json" }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  deleteVoter(cid, id) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .get(
        this.server_home_url +
          "/member/voter/delete/" +
          String(cid) +
          "/" +
          String(id),
        { headers: this.c_headers, responseType: "json" }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  /**Charts API */

  getMonthlyTrend(year) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .get(this.server_home_url + "/admin/monthly/trend/" + String(year), {
        headers: this.c_headers,
        responseType: "json",
      })
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getMonthlyTotalTrend(year, month) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .get(
        this.server_home_url +
          "/admin/monthly/trend/all/" +
          String(year) +
          "/" +
          String(month),
        { headers: this.c_headers, responseType: "json" }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getDailyTrend(year, month) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .get(
        this.server_home_url +
          "/admin/daily/trend/" +
          String(year) +
          "/" +
          String(month),
        { headers: this.c_headers, responseType: "json" }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getDailySnapshot(formdata) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });

    return this.http
      .post(
        this.server_home_url + "/admin/snapshot/daily",
        JSON.stringify(formdata),
        { headers: this.c_headers, responseType: "arraybuffer" }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getFavourites() {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .get(this.server_home_url + "/admin/my/favourites", {
        headers: this.c_headers,
        responseType: "json",
      })
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getConstituencyMonthlyTrend(cid) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .get(
        this.server_home_url +
          "/admin/month/trend/" +
          this.userService.getAdminConstituencyID(),
        { headers: this.c_headers, responseType: "json" }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  /**Bulk update*/

  bulkUpdateArea(formdata) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .post(
        this.server_home_url + "/admin/bulkupdate/area",
        JSON.stringify(formdata),
        { headers: this.c_headers, responseType: "json" }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }
  bulkUpdateBooth(formdata, cid) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .post(
        this.server_home_url + "/admin/bulkupdate/booths/" + String(cid),
        JSON.stringify(formdata),
        { headers: this.c_headers, responseType: "json" }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getRepEmails(formdata) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .post(
        this.server_home_url + "/admin/rep/emails",
        JSON.stringify(formdata),
        { headers: this.c_headers, responseType: "json" }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getRepContactnos(formdata) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .post(
        this.server_home_url + "/admin/rep/contactno",
        JSON.stringify(formdata),
        { headers: this.c_headers, responseType: "json" }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  uploadImage(file) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    let fileExt = file.name.split(".").pop();
    let formData: FormData = new FormData();
    formData.append(
      "eventimage",
      file,
      "event_image_" + generate() + "." + String(fileExt)
    );
    return this.http
      .post(this.server_home_url + "/member/eventimage/upload", formData, {
        headers: this.c_headers,
        responseType: "json",
      })
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  uploadAreaDetails(file, cid) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    let formData: FormData = new FormData();
    formData.append("areadetail", file, cid);
    return this.http
      .post(
        this.server_home_url + "/member/areadetails/upload/" + String(cid),
        formData,
        { headers: this.c_headers, responseType: "json" }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  deleteEventImage(file, cid) {
    let data = {
      filename: file,
    };
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .post(
        this.server_home_url + "/member/eventimage/delete/" + String(cid),
        data,
        { headers: this.c_headers, responseType: "json" }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }
  getEventImage(file, cid) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    let data = {
      filename: file,
    };
    return this.http
      .post(
        this.server_home_url + "/member/eventimage/get/" + String(cid),
        data,
        { headers: this.c_headers, responseType: "json" }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  createBulkEmail(formdata) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .post(
        this.server_home_url + "/admin/bulk/emails",
        JSON.stringify(formdata),
        { headers: this.c_headers, responseType: "json" }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  createBulkSMS(formdata) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .post(
        this.server_home_url + "/admin/bulk/sms",
        JSON.stringify(formdata),
        { headers: this.c_headers, responseType: "json" }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  /** Booth Vote Count API's */

  createBoothVoteCount(formdata, id) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .post(
        this.server_home_url + "/member/boothvote/create/" + String(id),
        JSON.stringify(formdata),
        { headers: this.c_headers, responseType: "json" }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  updateBoothVoteCount(cid, formdata) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .post(
        this.server_home_url + "/member/boothvote/update/" + String(cid),
        JSON.stringify(formdata),
        { headers: this.c_headers, responseType: "json" }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getBoothVoteCount(cid, id) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .get(
        this.server_home_url +
          "/member/boothvote/" +
          String(cid) +
          "/" +
          String(id),
        { headers: this.c_headers, responseType: "json" }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getBoothVoteCountByType(place, id, typeid) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .get(
        this.server_home_url +
          "/member/boothvote/" +
          String(place) +
          "/" +
          String(id) +
          "/" +
          String(typeid),
        { headers: this.c_headers }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  linkPollingStation(boothid, place) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    const formData = {
      place: boothid,
      pid: place,
    };
    return this.http
      .post(
        this.server_home_url + "/member/pollingbooth/link/" + String(place),
        JSON.stringify(formData),
        { headers: this.c_headers, responseType: "json" }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getElectionList() {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .get(this.server_home_url + "/member/election/list", {
        headers: this.c_headers,
        responseType: "json",
      })
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  /**
   * Payment Modules
   */

  getDonorDetail() {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .get(this.server_home_url + "/member/donor/details", {
        headers: this.c_headers,
        responseType: "json",
      })
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getMyPaymentHistory() {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .get(this.server_home_url + "/payment/me/paymenthistory", {
        headers: this.c_headers,
        responseType: "json",
      })
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getPaymentHistory(formdata, pno) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .post(
        this.server_home_url + "/payment/paymenthistory/" + String(pno),
        JSON.stringify(formdata),
        {
          headers: this.c_headers,
          responseType: "json",
        }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  createDonorDetails(formData) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .post(
        this.server_home_url + "/member/donor/create/details/",
        JSON.stringify(formData),
        { headers: this.c_headers, responseType: "json" }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  updateDonorDetails(formData, id) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .post(
        this.server_home_url + "/member/donor/update/details/" + String(id),
        JSON.stringify(formData),
        { headers: this.c_headers, responseType: "json" }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  paymentTrigger(formData) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .post(
        this.server_home_url + "/payment/paymentrequest",
        JSON.stringify(formData),
        { headers: this.c_headers, responseType: "json" }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getMyContribution() {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .get(this.server_home_url + "/payment/mycontribution", {
        headers: this.c_headers,
        responseType: "json",
      })
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getMontlyServerPayment(id, mid) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .get(
        this.server_home_url +
          "/payment/monthly/fee/" +
          String(id) +
          "/" +
          String(mid),
        {
          headers: this.c_headers,
          responseType: "json",
        }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  addManualPayment(formData) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .post(
        this.server_home_url + "/payment/donation/manaul/entry",
        JSON.stringify(formData),
        { headers: this.c_headers, responseType: "json" }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getMonthlyPaymentStatus() {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .get(this.server_home_url + "/member/monthly/payment/status", {
        headers: this.c_headers,
        responseType: "json",
      })
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getCountryListEN() {
    return this.http.get(this.server_home_url + "/thuli/country/list").pipe(
      map((data) => data),
      catchError(this.handleError)
    );
  }

  getStateList(id) {
    return this.http
      .get(this.server_home_url + "/thuli/state/list/" + String(id))
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getCityList(id) {
    return this.http
      .get(this.server_home_url + "/thuli/city/list/" + String(id))
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  /**
   * Module to Join/Unjoin Thuli Membership
   */

  joinThuli() {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .get(this.server_home_url + "/member/join/thuli", {
        headers: this.c_headers,
        responseType: "json",
      })
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  unjoinThuli() {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .get(this.server_home_url + "/member/unjoin/thuli", {
        headers: this.c_headers,
        responseType: "json",
      })
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  createCertificate(id) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .get(this.server_home_url + "/payment/paycertificate/" + String(id), {
        headers: this.c_headers,
        responseType: "arraybuffer",
      })
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getContributors(id) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .get(this.server_home_url + "/payment/contributors/" + String(id), {
        headers: this.c_headers,
        responseType: "json",
      })
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  updateContributors(id, value) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .post(
        this.server_home_url + "/payment/contributors/" + String(id),
        JSON.stringify(value),
        { headers: this.c_headers, responseType: "json" }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  /** Local Body */

  createCandidateRequest(value) {
    this.c_headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http
      .post(
        this.server_home_url + "/localbody/election/join/",
        JSON.stringify(value),
        { headers: this.c_headers, responseType: "json" }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  /** Bulk Downloads */

  getRepContacts(formData) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .post(
        this.server_home_url + "/admin/excel/reps/info",
        JSON.stringify(formData),
        { headers: this.c_headers, responseType: "blob" }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  /**Meetup Module */

  register4Event(formData) {
    this.c_headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http
      .post(
        this.server_home_url + "/meetup/register",
        JSON.stringify(formData),
        { headers: this.c_headers, responseType: "json" }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  sendInvitation(mid) {
    this.c_headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http
      .get(this.server_home_url + "/meetup/send/invitation/" + String(mid), {
        headers: this.c_headers,
        responseType: "json",
      })
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  validateMID(no) {
    return this.http
      .get(this.server_home_url + "/validate/member/" + String(no), {
        responseType: "text",
      })
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  isEligibleForEvent(mid) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .get(this.server_home_url + "/admin/event/attendence/" + String(mid), {
        headers: this.c_headers,
        responseType: "json",
      })
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  updateEventAttendence(mid, interest) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .get(
        this.server_home_url +
          "/admin/update/attendence/" +
          String(mid) +
          "/" +
          String(interest),
        { headers: this.c_headers, responseType: "json" }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getTotalAttended() {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .get(this.server_home_url + "/admin/event/entry/status", {
        headers: this.c_headers,
        responseType: "json",
      })
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  /** Candidate Information */

  getCandidateList() {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .get(this.server_home_url + "/member/election/candidate/list", {
        headers: this.c_headers,
        responseType: "json",
      })
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  createCandidate(fdata) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .post(
        this.server_home_url + "/member/election/candidate/appoint",
        JSON.stringify(fdata),
        { headers: this.c_headers, responseType: "json" }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  deleteCandidateInfo(id) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .get(
        this.server_home_url +
          "/member/election/candidate/delete/" +
          String(id),
        {
          headers: this.c_headers,
          responseType: "json",
        }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  updateCandidateInfo(fdata, id) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .post(
        this.server_home_url +
          "/member/election/candidate/update/" +
          String(id),
        JSON.stringify(fdata),
        { headers: this.c_headers, responseType: "json" }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  downloadExcel() {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .get(this.server_home_url + "/member/election/candidate/download/excel", {
        headers: this.c_headers,
        responseType: "arraybuffer",
      })
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  downloadPDF() {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .get(this.server_home_url + "/member/election/candidate/download/pdf", {
        headers: this.c_headers,
        responseType: "arraybuffer",
      })
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  registerITWing(fdata) {
    this.c_headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http
      .post(
        this.server_home_url + "/itwing/new/register",
        JSON.stringify(fdata),
        { headers: this.c_headers, responseType: "json" }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getITWingRegistars() {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .get(this.server_home_url + "/admin/itwing/db/export/excel", {
        headers: this.c_headers,
        responseType: "arraybuffer",
      })
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  /** Analytics API's */

  getDailyMemberTrend(formData) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .post(
        this.server_home_url + "/report/member/trend",
        JSON.stringify(formData),
        { headers: this.c_headers }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getDailyApprovalTrend(formData) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .post(
        this.server_home_url + "/report/member/approval/trend",
        JSON.stringify(formData),
        { headers: this.c_headers }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getGenderTrend(formData) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .post(
        this.server_home_url + "/report/member/gender/trend",
        JSON.stringify(formData),
        { headers: this.c_headers }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getAgeTrend(formData) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .post(
        this.server_home_url + "/report/member/age/trend",
        JSON.stringify(formData),
        { headers: this.c_headers }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }
  getMemberTrendByCons(formData) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .post(
        this.server_home_url + "/report/member/constituency/trend",
        JSON.stringify(formData),
        { headers: this.c_headers }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }
  getMemberCreatorByTrend(formData) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .post(
        this.server_home_url + "/report/member/creator/trend",
        JSON.stringify(formData),
        { headers: this.c_headers }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getEventTotal(formData) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .post(
        this.server_home_url + "/report/event/trend",
        JSON.stringify(formData),
        { headers: this.c_headers }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getEventTypeTrend(formData) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .post(
        this.server_home_url + "/report/event/type/trend",
        JSON.stringify(formData),
        { headers: this.c_headers }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getEventTypeByCons(formData) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .post(
        this.server_home_url + "/report/event/trend/constituency",
        JSON.stringify(formData),
        { headers: this.c_headers }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getRepsOverallTrend(formData) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .post(
        this.server_home_url + "/report/reps/trend",
        JSON.stringify(formData),
        { headers: this.c_headers }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  /** API to get constituency by Zone */

  getZoneList() {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .get(this.server_home_url + "/admin/zone/list", {
        headers: this.c_headers,
        responseType: "json",
      })
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  /** Member Drive API Functions */

  getMemberDriveList(cid) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .get(this.server_home_url + "/admin/memberdrive/list/" + String(cid), {
        headers: this.c_headers,
        responseType: "json",
      })
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  addMemberDrive(formData) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .post(
        this.server_home_url + "/admin/memberdrive/add",
        JSON.stringify(formData),
        { headers: this.c_headers }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  editMemberDrive(formData) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .post(
        this.server_home_url + "/admin/memberdrive/edit",
        JSON.stringify(formData),
        { headers: this.c_headers }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  deleteMemberDrive(formData) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .post(
        this.server_home_url + "/admin/memberdrive/delete",
        JSON.stringify(formData),
        { headers: this.c_headers }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  approveMemberDrive(formData) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .post(
        this.server_home_url + "/admin/memberdrive/approve",
        JSON.stringify(formData),
        { headers: this.c_headers }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  /**
   * Dashbaord for the district heads
   */

  getMemberTrendByDistrict(formData) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .post(
        this.server_home_url + "/report/district/member/trend",
        JSON.stringify(formData),
        { headers: this.c_headers }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getMemberTrendByDistrictSplit(formData) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .post(
        this.server_home_url + "/report/district/member/trend/split",
        JSON.stringify(formData),
        { headers: this.c_headers }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getMemberTopCreators(formData) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .post(
        this.server_home_url + "/report/district/member/details/split",
        JSON.stringify(formData),
        { headers: this.c_headers }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getBoothAgentTrendByDistrict(formData) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .post(
        this.server_home_url + "/report/district/booth/agent/trend",
        JSON.stringify(formData),
        { headers: this.c_headers }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getEventTrendByDistrict(formData) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .post(
        this.server_home_url + "/report/district/event/trend",
        JSON.stringify(formData),
        { headers: this.c_headers }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getRepTrendByDistrict(formData) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .post(
        this.server_home_url + "/report/district/administrator/trend",
        JSON.stringify(formData),
        { headers: this.c_headers }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getRepSplitByDistrict(formData) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .post(
        this.server_home_url + "/report/district/administrator/trend/split",
        JSON.stringify(formData),
        { headers: this.c_headers }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  /**
   * கிளை கட்டைமைப்பு API's
   *
   */

  getRootAreaParents() {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .get(this.server_home_url + "/admin/root/area/myconstituency", {
        headers: this.c_headers,
      })
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getRootAreas(cid) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .get(this.server_home_url + "/admin/root/area/" + String(cid), {
        headers: this.c_headers,
      })
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  createRootArea(formData) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .post(
        this.server_home_url + "/admin/root/area",
        JSON.stringify(formData),
        { headers: this.c_headers }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  updateRootArea(formData) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .post(
        this.server_home_url + "/admin/root/area/update",
        JSON.stringify(formData),
        { headers: this.c_headers }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  deleteRootArea(id: any) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .get(this.server_home_url + "/admin/root/area/delete/" + String(id), {
        headers: this.c_headers,
      })
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getRootAreaReps(id, depart) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .get(
        this.server_home_url +
          "/admin/root/area/reps/" +
          String(id) +
          "/" +
          String(depart),
        {
          headers: this.c_headers,
        }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getRootAreaRepsByConstituency(id) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .get(this.server_home_url + "/admin/root/area/reps/all" + String(id), {
        headers: this.c_headers,
      })
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  createRootAreaRep(formData) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .post(
        this.server_home_url + "/admin/root/area/reps",
        JSON.stringify(formData),
        { headers: this.c_headers }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  updateRootAreaRep(formData) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .post(
        this.server_home_url + "/admin/root/area/reps/update",
        JSON.stringify(formData),
        { headers: this.c_headers }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  deleteRootAreaRep(formData) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .post(
        this.server_home_url + "/admin/root/area/reps/delete",
        JSON.stringify(formData),
        { headers: this.c_headers }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getRepsInfoPDF(formdata) {
    this.c_headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.userService.getToken()}`,
    });
    return this.http
      .post(
        this.server_home_url + "/admin/reps/magazine/verify/pdf",
        { data: formdata },
        {
          headers: this.c_headers,
          responseType: "arraybuffer",
        }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }
}
